/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/anchor-is-valid */
// import { isServer } from '@utils/helper';
import Link, { LinkProps } from 'next/link';
import { memo, ReactElement } from 'react';

interface Props {
  href: LinkProps['href'] | string;
  children: ReactElement;
  className?: string;
}

const LinkInOutApp = memo(({ href, children, className }: Props) => {
  // const onClick = () =>
  //   !isServer && typeof href === 'string' ? window.open(href, '_blank') : null;

  return typeof href === 'string' &&
    (href.indexOf('http://') === 0 || href.indexOf('https://') === 0) ? (
    <a target="_blank" className={className} rel="noreferrer" href={href}>
      {children}
    </a>
  ) : (
    <Link href={href || '/'} className={className} rel="noreferrer">
      {children}
    </Link>
  );
});

export default LinkInOutApp;
